import { createStore } from 'vuex'
import type { Store } from 'vuex'
import login from './login'
import type IAllState from './types'
import { IRootState } from './types'

const store = createStore<IRootState>({
  modules: {
    login
  }
})
interface TypeCustomStore extends Store<IRootState> {
  state: IAllState
}
export default store as unknown as TypeCustomStore
