import xyRequest from '../utils/request'

/**
 * 获取用户信息
 */
export const getUserInfo = (id: string) => {
  return xyRequest.request({
    url: `/users/${id}`,
    method: 'get'
  })
}
/**
 * 获取菜单列表
 */
export const getMenuList = (id: string) => {
  return xyRequest.request({
    url: `/menu/${id}`,
    method: 'get'
  })
}
