import xyRequest from '../utils/request'
/**
 * 头部分类
 */
export const classify = (data: any) => {
  return xyRequest.request({
    url: '/api/templateGroup/list',
    data,
    method: 'post'
  })
}
/**
 * 获取模版列表
 */
export const listContent = (params: any) => {
  return xyRequest.request({
    url: '/api/template/list',
    params,
    method: 'get'
  })
}
/**
 * 设置模版内容
 */
export const setTemplate = (data: any) => {
  return xyRequest.request({
    url: '/api/template/getParamByTemplate',
    data,
    method: 'post'
  })
}

/**
 * 生成模版对话
 */
export const createTemplate = (data: any) => {
  return xyRequest.request({
    url: '/api/template/createWordByTemplate',
    data,
    method: 'post'
  })
}
/**
 * 会话列表
 */
export const getChatList = (params: any) => {
  return xyRequest.request({
    url: '/api/dialogGroup/list',
    params,
    method: 'get'
  })
}
/**
 * 创建会话列表
 */
export const createChatList = (data: any) => {
  return xyRequest.request({
    url: 'api/dialogGroup/saveDialogGroup',
    data,
    method: 'post'
  })
}
/**
 * 会话列表中对应详细内容
 */
export const getHistoryDialog = (params: any) => {
  return xyRequest.request({
    url: '/api/dialog/getHistoryDialog',
    params,
    method: 'get'
  })
}
/**
 * 会话列表修改名称
 */
export const postChangeGroupName = (data: any) => {
  return xyRequest.request({
    url: '/api/dialogGroup/changeGroupName',
    data,
    method: 'post'
  })
}
/**
 * 会话列表删除
 */
export const getChatListDelete = (data: any) => {
  return xyRequest.request({
    url: '/api/dialogGroup/deleteDialogGroup',
    data,
    method: 'post'
  })
}
/**
 * 发送会话消息
 */
export const sendChatDialog = (data: any) => {
  return xyRequest.request({
    url: 'http://43.154.65.78:22/api/dialog/getChatInfo',
    data,
    method: 'post'
  })
}

/**
 * 对会话进行 点赞或者点差
 */
export const feedbackAnswer = (data: any) => {
  return xyRequest.request({
    url: '/api/dialog/feedbackAnswer',
    data,
    method: 'post'
  })
}

/**
 * 微信分享
 */
export const sharePage = (data: any) => {
  return xyRequest.request({
    url: '/api/customer/getPublicAppInfo',
    data,
    method: 'post'
  })
}