import xyRequest from '../utils/request'
import { ILoginType,smsListType } from './types'

/**
 * 登录
 */
export const login = (data: any) => {
  return xyRequest.request({
    url: '/api/customer/loginByMobile',
    data,
    method: 'post'
  })
}


/**
 * 通过AI编码  登录
 */
export const loginByAICode = (data: any) => {
  return xyRequest.request({
    url: '/api/customer/loginByAICode',
    data,
    method: 'post'
  })
}

/**
 * 图像验证码
 */
export const imageCode = (data:any) => {
  return xyRequest.request({
    url: '/api/captchaImage',
    data,
    method: 'get'
  })
}
/**
 * 获取短信验证码
 */
export const sendSmsCode = (data:any) => {
  return xyRequest.request({
    url: '/api/customer/sendSms',
    data,
    method: 'post'
  })
}

