import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosPromise } from 'axios'
import store from '@/store/index';
import { ElMessage, ElMessageBox } from 'element-plus'
class Request {
    instance: AxiosInstance
    constructor() {
        this.instance = axios.create({
            baseURL: process.env.VUE_APP_BASE_URL as string,
            timeout:10000,
            headers: {'Content-Type': "application/json"}
        })
        this.useRequestInterceptors()
        this.useResponseinterceptors()
    }
    public request(config: AxiosRequestConfig): AxiosPromise {
        return this.instance.request(config)
    }
    private useRequestInterceptors() {
        this.instance.interceptors.request.use((config) => {
            const token = store.state.login.token;
            if (token) {
                config.headers!.token = `${token}`
            }
            return config
        })
    }
    private useResponseinterceptors() {
        this.instance.interceptors.response.use((res) => {
            if(res.data.code == 200){
                return res.data;
            }else if(res.data.code == 401){// token失效
                ElMessage({
                    message: '当前用户已过期，请重新登录',
                    type: 'error',
                    duration: 5 * 1000
                })
                store.commit("login/SET_TOKEN",'');
                store.commit("login/SET_USER_INFO",'');

            }else{
                ElMessage({
                    message: res.data.msg,
                    type: 'error',
                    duration: 5 * 1000
                })
            }
        },(err) => {
            console.log("err: ",err);
            ElMessage({
                message: err.message,
                type: 'error',
                duration: 5 * 1000
            })
        })
    }
}


export default new Request()
