export const getItem = (key:any) => {
  const data = window.localStorage.getItem(key)
  if (!data) return null
  try {
		return JSON.parse(data);
  } catch (error) {
    return null
  }
}

//联合类型
export const setItem = (key:any, value:any) => {
    value = JSON.stringify(value)
  window.localStorage.setItem(key, value)
}

export const removeItem = (key:any) => {
  window.localStorage.removeItem(key)
}

export const clearItem = () => {
  window.localStorage.clear()
}
