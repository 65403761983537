import router from '@/router/index'
import { Module } from 'vuex'
import { ElMessage } from 'element-plus'
import type { ILoginState, IRootState } from '../types'
import type { ILoginType } from '../../api/types'
import { login } from '../../api/login'
import { getUserInfo, getMenuList } from '../../api/user'
import { getItem, setItem } from '@/utils/storage';
const loginState: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: getItem('token') || '',
      userinfo: getItem('userinfo') || {},
	  currentIndex:  getItem('currentIndex') || 0,
	  modalObj: {},
    }
  },
  mutations: {
	SET_MODALCREATE(state, modal: any) {
		state.modalObj = modal;
	},
	SET_CURRENTINDEX(state, currentIndex: any) {
		state.currentIndex = currentIndex;
        setItem('currentIndex', currentIndex)
	},
    SET_TOKEN(state, token: any) {
      state.token = token;
      setItem('token', token)
    },
    SET_USER_INFO(state, userinfo: any) {
      state.userinfo = userinfo
      setItem('userinfo', userinfo)
    },
  },
  actions: {
    async onLogin({ commit }, payload: ILoginType) {
      try {
        const { data: res } = await login(payload)
        commit('SET_TOKEN', res.token)
        const [userInfoRes, userMenusRes] = await Promise.all([
          getUserInfo(res.id),
          getMenuList(res.id)
        ])
        commit('SET_USER_INFO', userInfoRes.data)
        commit('SET_USER_MENUS', userMenusRes.data)
        ElMessage.success('登录成功')
        router.push('/')
      } catch (error: any) {
        ElMessage.error(error.response.data)
      }
    }
  }
}
export default loginState
