import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import "./assets/css/reset.css"
import 'element-plus/dist/index.css';
import * as ELIcons from '@element-plus/icons-vue';
import mitt from 'mitt';
//创建Mitt实例
const Mit = mitt();
declare module 'vue'{
    export interface ComponentCustomProperties{
        $Bus:typeof Mit
    }
}
const app = createApp(App);
for (const name in ELIcons) {
    app.component(name, (ELIcons as any)[name]);
}
//vue挂载全局api                                                
app.config.globalProperties.$Bus = Mit
app.use(router);
app.use(ElementPlus).mount('#app');

