import { title } from 'process';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from "@/store";
export const Layout = () => import("@/layout/index.vue");
const routes : Array<RouteRecordRaw> = [
	//首页
	{
		path: '/',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		redirect: "/indexView",
		component: Layout,
		children: [
			{
				path: "/indexView/:code?",
				// path: "/indexView",
				component: () => import("@/views/indexView.vue"),
				name: "indexView",
				meta: {
					title: '紫猫跨境AI'
				}
				// meta: { title: "index", icon: "homepage", affix: true },
			},{
				path: "/chat/:code?",
				component: () => import("@/views/chatBDView.vue"),
				name: "chat",
				meta: {
					title: '紫猫AI'
				}
			},{
				path: "/recharge",
				component: () => import("@/views/recharge.vue"),
				name: "recharge",
			},{
				path: "/test",
				component: () => import("@/views/test.vue"),
				name: "test",
			},{
				path: "/order",
				component: () => import("@/views/order.vue"),
				name: "order",
			},{
				path: "/orderList",
				component: () => import("@/views/orderList.vue"),
				name: "orderList",
			},{
				path: "/flower",
				component: () => import("@/views/flower.vue"),
				name: "flower",
			},
			// {
			//   path: "404",
			//   component: () => import("@/views/error-page/404.vue"),
			//   meta: { hidden: true },
			// },
		],
	}
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
});
router.beforeEach((to, from, next) => {
	if (typeof (to.meta?.title) === 'string') {
		document.title = to.meta?.title;
	}
	if (to.path.includes('indexView')) {
		store.commit("login/SET_CURRENTINDEX", 0);
		next()
	} else if(to.path.includes('chat')) {
		store.commit("login/SET_CURRENTINDEX", 1);
		next()
	}else{
		next()
	}
})


export default router;